import React from "react";
import { Entity } from "Components/Entity/Entity";
import { useSubscription } from "@apollo/client";
import { GET_JOBS_FOR_PROFILE } from "GraphQL/operations";
import { useParams } from "react-router-dom";
import { Page } from "NewComponents/Page/Page";
import { Content } from "NewComponents/Content/Content";
import { Text } from "NewComponents/Text/Text";
import { PageLoader } from "NewComponents/PageLoader/PageLoader";

export const Profile = () => {
  const { profile } = useParams();

  const { data = { jobs: [] }, loading } = useSubscription(
    GET_JOBS_FOR_PROFILE,
    {
      variables: {
        profile: profile,
      },
      skip: !profile,
    }
  );

  const renderProfileName = () => {
    return (
      <h2 style={{ textAlign: "center", color: "var(--app-theme)" }}>
        <a
          href={`https://www.instagram.com/${profile}`}
          target="_blank"
          rel="noreferrer"
        >
          @{profile}
        </a>
      </h2>
    );
  };

  const renderProfileContent = () => {
    const jobs = data.jobs;

    if (jobs.length) {
      return (
        <Content middle flex row wrap scroll height="auto" maxHeight="100%">
          {jobs.map((job) => (
            <Entity key={job.id} job={job} />
          ))}
        </Content>
      );
    } else {
      return (
        <Content middle height="90%">
          <Text bold textCenter>
            It's kinda lonely in here
          </Text>
        </Content>
      );
    }
  };

  return (
    <Page opaque height="85%">
      {renderProfileName()}

      {!loading ? (
        renderProfileContent()
      ) : (
        <Content height="90%">
          <PageLoader transparent={false} />
        </Content>
      )}
    </Page>
  );
};
