import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Download } from "Features/Downloads/components/View/Download";
import { Profile } from "Features/Downloads/components/View/Profile";
import { List } from "Features/Downloads/components/List/List";
import { Profiles } from "Features/Profiles/Profiles";
import { Favorites } from "Features/Favorites/Favorites";
import { PrivateRoute } from "Components/PrivateRoute/PrivateRoute";
import "./styles/style.css";

export const Downloads = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate replace to="/downloads" />
          </PrivateRoute>
        }
      />
      <Route
        path="/downloads"
        element={
          <PrivateRoute>
            <List />
          </PrivateRoute>
        }
      />
      <Route
        path="/downloads/:id"
        element={
          <PrivateRoute>
            <Download />
          </PrivateRoute>
        }
      />
      <Route
        path="/favorites"
        element={
          <PrivateRoute>
            <Favorites />
          </PrivateRoute>
        }
      />
      <Route
        path="/profiles"
        element={
          <PrivateRoute>
            <Profiles />
          </PrivateRoute>
        }
      />
      <Route
        path="/profiles/:profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
