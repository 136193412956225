import { Downloads } from "./Features/Downloads/Downloads";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Create } from "Features/Downloads/components/Create/Create";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "Firebase/config";
import { getDatabase, ref, onValue } from "firebase/database";
import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { getApolloClient } from "GraphQL/config";
import { NavigationTabs } from "Components/NavigationTabs/NavigationTabs";
import { appUser } from "Common/AppContext";
import { GET_USER } from "GraphQL/operations";
import { Tag } from "Features/Tags/Tag";
import { NewLogin } from "Features/NewLogin/NewLogin";
import { AppWrapper } from "Components/AppWrapper/AppWrapper";
import { Logout } from "Features/Logout/Logout";
import { AppLoader } from "NewComponents/AppLoader/AppLoader";
import "./App.css";

function App() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState<string>("");
  useEffect(() => {
    if (!user && !loading) {
      setAuthToken("");
    }
  }, [user, loading]);
  const u = useReactiveVar(appUser);

  const userContextUpdate = useCallback(async () => {
    if (user && !u) {
      const t = await user.getIdToken();
      const client = getApolloClient(t);
      const { data } = await client.query({
        query: GET_USER,
        variables: { firebase_auth_id: user.uid },
      });
      console.debug("setting user for application");
      appUser(data.users[0]);
    }
  }, [user, u]);

  useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          const token = await user.getIdToken();
          await userContextUpdate();
          const idTokenResult = await user.getIdTokenResult();
          const hasuraClaim =
            idTokenResult.claims["https://hasura.io/jwt/claims"];
          if (hasuraClaim) {
            setAuthToken(token);
          } else {
            // Check if refresh is required.
            const db = getDatabase();
            const metadataRef = ref(
              db,
              "metadata/" + user.uid + "/refreshTime"
            );
            onValue(metadataRef, async (data) => {
              if (!data.exists) {
                console.log("data exists");
                navigate(window.location.pathname);
                return;
              }
              // Force refresh to pick up the latest custom claims changes.
              const token = await user.getIdToken(true);
              setAuthToken(token);
              navigate(window.location.pathname);
            });
          }
        } else {
          navigate("/login");
        }
      }
    })();
  }, [user, navigate, loading, userContextUpdate]);

  return (
    <>
      <ApolloProvider client={getApolloClient(authToken)}>
        <AppWrapper authToken={authToken}>
          <Routes>
            <Route path="/login" element={<NewLogin />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>

          <>
            <Create />
            <NavigationTabs />
            {!loading ? (
              <>
                <Downloads />
                <Tag />
              </>
            ) : (
              <AppLoader />
            )}
          </>
        </AppWrapper>
      </ApolloProvider>
    </>
  );
}

export default App;
