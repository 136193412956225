import { IconButton } from "evergreen-ui";
import React from "react";
import "./styles.css";
import { Thumbs } from "NewComponents/Icons/Thumb/Thumb";

export const ThumbsButton = ({ isLiked = false, onClick }) => {
  return (
    <div className="icon-button">
      <IconButton
        icon={
          <Thumbs
            color="white"
            type={isLiked ? "fill" : "outline"}
            size={20}
            dropShadow
          />
        }
        onClick={onClick}
      />
    </div>
  );
};
