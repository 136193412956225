export const createJobVariablesFromLink = (link: string, userId: number) => {
  const variables = {
    metadata: {},
    type: '',
    created_by: userId
  }
  const url = link.split("/")
  const url_type = url[3]

  switch (url_type) {
    case "p":
      variables.type = "POST"
      variables.metadata["shortcode"] = url[4]
      break;
    case "reel":
      variables.type = "REEL"
      variables.metadata["shortcode"] = url[4]
      break;

    case "stories":
      variables.type = "STORY"
      variables.metadata["profile"] = url[4]
      let clean = url[5]
      const media_id = clean.split("?")
      variables.metadata["media_id"] = media_id[0]
      break;
    default:
      if (url_type !== "" && url[2] === "www.instagram.com") {
        variables.type = "PROFILE"
        variables.metadata["profile"] = url_type
      }
      else {
        throw new Error('Type Not Handled')
      }
      break;
  }

  return variables
}

export const retrieveJobResults = async (job_ids) => {

  const body = { job_ids };
  const request = await fetch(`${process.env.REACT_APP_JOB_SERVICE_BACKEND_URI}/jobs/retrieve/`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const response = await request.json();
  return response
}

export const triggerJob = async (job_id) => {
  const body = { job_id };

  const request = await fetch(
    `${process.env.REACT_APP_JOB_SERVICE_BACKEND_URI}/webhooks/trigger/job/`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  await request.json();
  return true
}

export const generateProfileUrl = (profile: string) => {

  return `https://www.instagram.com/${profile}`
}