import { useQuery } from "@apollo/client";
import { GET_ALL_TAGS } from "GraphQL/operations";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { Page } from "NewComponents/Page/Page";
import { Content } from "NewComponents/Content/Content";
import { Text } from "NewComponents/Text/Text";
import { PageLoader } from "NewComponents/PageLoader/PageLoader";
import { Tags } from "NewComponents/Tags/Tags";

export const TagsList = () => {
  const { data = { tags: [] }, loading } = useQuery(GET_ALL_TAGS);
  const navigate = useNavigate();

  const renderTags = () => {
    const tags = data.tags;

    if (tags.length) {
      return (
        <Content middle flex row wrap scroll height="auto" maxHeight="100%">
          {tags.map(({ id, name }) => (
            <Tags
              key={id}
              tag={name}
              onClick={() => navigate(`/tags/${name}`)}
            />
          ))}
        </Content>
      );
    } else {
      return (
        <Content middle>
          <Text bold textCenter>
            It's kinda lonely in here
          </Text>
        </Content>
      );
    }
  };

  return (
    <Page opaque height="85%">
      {!loading ? (
        renderTags()
      ) : (
        <Content>
          <PageLoader transparent={false} />
        </Content>
      )}
    </Page>
  );
};
