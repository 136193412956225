import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "Components/Spinner/Spinner";
import { retrieveJobResults } from "Features/Downloads/helpers";
import { Link, useNavigate, useParams } from "react-router-dom";
import { appBackground } from "Common/AppContext";
import { useMutation, useQuery } from "@apollo/client";
import { GET_JOB, UPDATE_THUMBNAIL } from "GraphQL/operations";
import { Dialog, TagInput } from "evergreen-ui";
import { useTags } from "Features/Downloads/hooks/useTags";
import { Page } from "NewComponents/Page/Page";
import { Content } from "NewComponents/Content/Content";
import { Image } from "NewComponents/Image/Image";
import { Video } from "NewComponents/Video/Video";

export const Download = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addTag, removeTag, tags: tagsDropDowbn } = useTags(id);

  const { data } = useQuery(GET_JOB, {
    variables: {
      id,
    },
    skip: !id,
  });

  const [updateThumbnail] = useMutation(UPDATE_THUMBNAIL);

  const [result, setResult] = useState(
    (undefined as { media: []; name: string }) || undefined
  );
  const [dialogState, setDialogState] = useState({});
  useEffect(() => {
    async function fetchJob() {
      const response = await retrieveJobResults([id]);
      setResult(response);
    }

    if (data && data.jobs.length) {
      fetchJob();
    }
  }, [data, id]);

  const tags = useMemo(() => {
    if (data && data.jobs.length) {
      const tags = data.jobs[0].tag_links.map(({ tag }) => tag.name);
      return tags;
    } else {
      return [];
    }
  }, [data]);

  const fiteredTags = useMemo(() => {
    return tagsDropDowbn.filter(({ name }) => !tags.includes(name));
  }, [tagsDropDowbn, tags]);

  useEffect(() => {
    if (result && result.media.length) {
      let newDialogState = {};
      for (let media of result.media) {
        const src = media as string;
        if (src.endsWith(".jpg")) {
          newDialogState = { ...newDialogState, [src]: false };
        }
      }
      setDialogState(newDialogState);
      for (let media of result.media) {
        const src = media as string;
        if (src.endsWith(".jpg")) {
          appBackground(media);
          break;
        }
      }
    }
  }, [result, setDialogState]);

  useEffect(() => {
    return () => {
      appBackground("");
    };
  }, []);

  const renderTagSection = useCallback(() => {
    return (
      <div style={{ padding: "16px" }}>
        <TagInput
          values={tags}
          width="100%"
          inputProps={{ placeholder: "Add Tags" }}
          autocompleteItems={fiteredTags.map(({ name }) => name)}
          onAdd={(tag) => {
            if (tag) {
              addTag(tag[0]).then(() => {
                console.log("added");
              });
            }
          }}
          onRemove={(tag) => {
            if (tag) {
              removeTag(tag[0]).then(() => {
                console.log("removed");
              });
            }
          }}
          tagProps={(value) => {
            return {
              onClick: () => {
                navigate(`/tags/${value}`);
              },
            };
          }}
        />
      </div>
    );
  }, [tags, fiteredTags, addTag, removeTag, navigate]);

  const renderDialog = (src: string) => {
    return (
      <Dialog
        isShown={dialogState[src]}
        title="Change Thumbnail"
        onCloseComplete={() => setDialogState({ ...dialogState, [src]: false })}
        onConfirm={async () => {
          await updateThumbnail({
            variables: {
              id: id,
              thumbnail: src,
            },
          });
          setDialogState({ ...dialogState, [src]: false });
        }}
        onCancel={() => setDialogState({ ...dialogState, [src]: false })}
        confirmLabel="Change Thumbnail"
      >
        Do you want to change thumbnail?
      </Dialog>
    );
  };

  const renderProfileHeader = () => {
    return result ? (
      <Link to={`/profiles/${result.name}`}>
        <h2 style={{ color: "var(--app-theme)", textAlign: "center" }}>
          @{result.name}
        </h2>
      </Link>
    ) : (
      <></>
    );
  };

  const videoFiles = useMemo(() => {
    return result?.media.filter((src: string) => src.endsWith(".mp4"));
  }, [result]);

  const imageFiles = useMemo(() => {
    return result?.media.filter((src: string) => !src.endsWith(".mp4"));
  }, [result]);

  const renderContent = () => {
    return (
      <Content middle flex row wrap scroll height="85%">
        {imageFiles?.map((src: string) => {
          return (
            <>
              <Image
                imageSrc={src}
                onClick={() => {
                  setDialogState({ ...dialogState, [src]: true });
                }}
              />
              {renderDialog(src)}
            </>
          );
        })}
        {videoFiles?.map((src: string) => {
          return (
            <>
              <Video src={src} />
            </>
          );
        })}
      </Content>
    );
  };

  return (
    <Page height="85%">
      {result ? (
        <>
          {renderTagSection()}
          {renderProfileHeader()}
          {renderContent()}
        </>
      ) : (
        <Content middle>
          <Spinner size={48} />
        </Content>
      )}
    </Page>
  );
};
