import { useQuery } from "@apollo/client";
import { AuthContext } from "Features/Auth/AuthContext";
import { auth } from "Firebase/config";
import { GET_USER } from "GraphQL/operations";
import React, { useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

export const AppWrapper = ({ authToken, children }) => {
  const [firebaseUser] = useAuthState(auth);
  const { data, loading } = useQuery(GET_USER, {
    variables: {
      firebase_auth_id: firebaseUser ? firebaseUser.uid : "",
    },
    skip: !firebaseUser || !authToken,
  });
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const appUser = useMemo(() => {
    return data ? data.users[0] : null;
  }, [data]);
  return (
    <AuthContext.Provider
      value={{
        user: appUser,
        isLoggingIn,
        setIsLoggingIn,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
