import React from "react";
import { useSearchParams } from "react-router-dom";

export const Search = ({ updateFilter }) => {
  const [params] = useSearchParams();
  const handleChange = (e) => {
    updateFilter(e.target.value);
  };
  return (
    <div style={{ width: "100%" }}>
      <input
        className="input"
        placeholder="Search Profiles"
        onChange={handleChange}
        defaultValue={params.get("search") || ""}
      ></input>
    </div>
  );
};
