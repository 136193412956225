import { Content } from 'NewComponents/Content/Content'
import { Page } from 'NewComponents/Page/Page'
import React from 'react'

export const Logout = () => {
    return <Page opaque>
        <Content middle>
            Logged Out!
        </Content>
    </Page>
}