import { Badge } from "evergreen-ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DeleteButton } from "Components/Buttons/DeleteButton";
import { useMutation } from "@apollo/client";
import { DELETE_JOBS, UPDATE_FAVORITE } from "GraphQL/operations";
import { ThumbsButton } from "Components/Buttons/ThumbsButton";
import "./styles.css";
import { Image } from "NewComponents/Image/Image";

export type BadgeColor =
  | "neutral"
  | "automatic"
  | "blue"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "purple";

export const Entity = ({ job }) => {
  const navigate = useNavigate();
  const [deleteJob] = useMutation(DELETE_JOBS);
  const [toggleFavorite] = useMutation(UPDATE_FAVORITE);
  const renderBadgeFromJobStatus = (status) => {
    let color: BadgeColor = "neutral";

    switch (status.value) {
      case "FINISHED":
        color = "green";
        break;
      case "IN_PROGRESS":
        color = "blue";
        break;
      case "CREATED":
        color = "purple";
        break;
      case "PROCESSING":
        color = "yellow";
        break;
      case "FAILED":
        color = "red";
        break;
      case "PAUSED":
        color = "neutral";
        break;
      default:
        color = "neutral";
    }

    return (
      <Badge className="badge" color={color}>
        {status.label}
      </Badge>
    );
  };

  const imageSrc =
    job.thumbnail ||
    "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png";

  const renderTopLeftElement = () => {
    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ fontSize: "0.5rem" }}
      >
        <ThumbsButton
          isLiked={job.is_favorite}
          onClick={() => {
            toggleFavorite({
              variables: {
                id: job.id,
                is_favorite: !job?.is_favorite,
              },
            });
          }}
        />
      </span>
    );
  };

  const topRightElement = () => {
    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ fontSize: "0.5rem" }}
      >
        <DeleteButton
          onClick={() => {
            deleteJob({
              variables: {
                ids: [job.id],
              },
            });
          }}
        />
      </span>
    );
  };

  const bottomLeftElement = () => {
    return (
      <>
        <span style={{ fontWeight: 600, fontSize: "0.8rem" }}>
          {job.profile}
        </span>
        <span>
          {new Date(job.created_at).toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
          })}
          ,{" "}
          {new Date(job.created_at).toLocaleTimeString("en-us", {
            hour: "numeric",
            minute: "numeric",
            hourCycle: "h12",
          })}
        </span>
      </>
    );
  };

  const bottomRightElement = () => {
    return (
      <>
        <span style={{ fontSize: "0.8rem" }}>{job.job_type.label}</span>
        <span style={{ fontSize: "0.5rem" }}>
          {renderBadgeFromJobStatus(job.job_status)}
        </span>
      </>
    );
  };

  const handleOnClick = () => {
    navigate(`/downloads/${job.id}`);
  };
  return (
    <Image
      imageSrc={imageSrc}
      onClick={handleOnClick}
      topLeftElement={renderTopLeftElement()}
      topRightElement={topRightElement()}
      bottomLeftElement={bottomLeftElement()}
      bottomRightElement={bottomRightElement()}
    />
  );
};
