import { useEffect, useState } from "react";
import React from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import { CREATE_JOB } from "GraphQL/operations";
import {
  createJobVariablesFromLink,
  triggerJob,
} from "Features/Downloads/helpers";
import "./styles/styles.css";
import { appUser } from "Common/AppContext";
import { toaster } from "evergreen-ui";

export const Create = () => {
  const [input, setInput] = useState("");

  const user = useReactiveVar(appUser);
  const [createJob, { loading, error }] = useMutation(CREATE_JOB);

  useEffect(() => {
    error && toaster.notify(error.message);
  }, [error]);

  const handleOnSubmit = async () => {
    const variables = createJobVariablesFromLink(input, user.id);
    const { data }: any = await createJob({
      variables,
    });

    await triggerJob(data.insert_jobs.returning[0].id);
    setInput("");
  };

  const handleInputOnChange = async ({ target }) => {
    setInput(target.value);
  };

  return (
    <div className="controls-container">
      <input
        className="input"
        disabled={loading}
        placeholder={"copy link here"}
        value={input}
        onChange={handleInputOnChange}
      ></input>
      <button className="app-button" onClick={handleOnSubmit}>
        DOWNLOAD
      </button>
    </div>
  );
};
