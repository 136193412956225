import clsx from 'clsx'
import React, { ReactNode } from 'react'
import './styles.css'
export type TTextProps = {
    children?: ReactNode
    textCenter?: boolean
    bold?: boolean
}

export const Text : React.FC<TTextProps> = ({children, textCenter = false, bold = false}) => {
    return <p className={clsx('text', textCenter && 'text-center', bold && 'text-bold')}>{children}</p>
}