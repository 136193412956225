import { auth } from "Firebase/config";
import { AppLoader } from "NewComponents/AppLoader/AppLoader";
import { signInWithCustomToken } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const NewLogin = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loggingIn, setLoggingIn] = useState(false);

  useEffect(() => {
    const token = params.get("token");
    async function login() {
      setLoggingIn(true);
      await signInWithCustomToken(auth, params.get("token"));
      setLoggingIn(false);
      navigate("/");
    }
    if (token) {
      login();
    } else {
      if (!loggingIn) {
        console.log("Redirecting to Auth Service Login");
        window.location.href = `https://auth.skylark.cloud/?redirect=https://insta.skylark.cloud/login`;
        //window.location.href = `http://localhost:3001/?redirect=http://localhost:3000/login`;
      }
    }
  }, [loggingIn, params, navigate]);
  return (
    <div>
      {loggingIn && <AppLoader />}
    </div>
  );
};
