import { PrivateRoute } from "Components/PrivateRoute/PrivateRoute";
import { TagsList } from "Features/Tags/List/TagsList";
import { TagsMain } from "Features/Tags/View/TagsMain";
import React from "react";
import { Route, Routes } from "react-router-dom";

export const Tag = () => {
  return (
    <Routes>
      <Route
        path="/tags/:tag"
        element={
          <PrivateRoute>
            <TagsMain />
          </PrivateRoute>
        }
      />
      <Route
        path="/tags"
        element={
          <PrivateRoute>
            <TagsList />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
