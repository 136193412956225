import { Button } from "NewComponents/Button/Button";
import { auth } from "Firebase/config";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";

export const LogoutButton = () => {
  const [user] = useAuthState(auth);

  const handleLogoutClick = async () => {
    await signOut(auth);
    window.location.href =
      "https://auth.skylark.cloud/logout?redirect=https://insta.skylark.cloud/logout";
  };

  return user ? <Button onClick={handleLogoutClick}>LOGOUT</Button> : <></>;
};
