import { IIconProps } from "NewComponents/Icons/types";
import React from "react";

export const Trash: React.FC<IIconProps> = ({
  size = 24,
  type = "outline",
  color = "white",
  dropShadow = false,
}) => {
  const styles = {
    filter: dropShadow ? "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))" : "none",
  };
  return (
    <svg
      style={styles}
      viewBox="0 0 24 24"
      fill={type === "fill" ? color : "none"}
      height={`${size}px`}
      width={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M6 5H18M9 5V5C10.5769 3.16026 13.4231 3.16026 15 5V5M9 20H15C16.1046 20 17 19.1046 17 18V9C17 8.44772 16.5523 8 16 8H8C7.44772 8 7 8.44772 7 9V18C7 19.1046 7.89543 20 9 20Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
};
