import { Pane, Tablist, Tab } from "evergreen-ui";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.css";
export const tabs = [
  { label: "Downloads", path: "/downloads" },
  { label: "Favorites", path: "/favorites" },
  { label: "Profiles", path: "/profiles" },
  { label: "Tags", path: "/tags" },
];
export const NavigationTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Pane className="navigation-tabs">
      <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
        {tabs.map((t) => {
          return (
            <Tab
              aria-controls={`panel-${t}`}
              isSelected={location.pathname.startsWith(t.path)}
              key={t.label}
              onSelect={() => {
                navigate(t.path);
              }}
            >
              <b>{t.label.toUpperCase()}</b>
            </Tab>
          );
        })}
      </Tablist>
    </Pane>
  );
};
