import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

export const getApolloClient = (token) => {
  const http = createHttpLink({
    uri: process.env.REACT_APP_HASURA_BACKEND_URI,
  });


  const auth = setContext((_, { headers }) => {
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      return {
        headers: {
          ...headers,
          "x-hasura-admin-secret": process.env.REACT_APP_HASURA_ADMIN_SECRET,
        },
      };
    }
  });

  const ws = new GraphQLWsLink(
    createClient({
      url: "wss://hasura.skylark.cloud/v1/graphql",
      retryAttempts: Infinity,
      shouldRetry: () => true,
      keepAlive: 10000,
      on: {
        closed: () => {
          console.debug("WebSocket Closed At :", new Date());
        },
      },

      connectionParams: async () => {
        if (token) {
          return {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        } else {
          return {
            headers: {
              "x-hasura-admin-secret":
                process.env.REACT_APP_HASURA_ADMIN_SECRET,
            },
          };
        }
      },
    })
  );

  const httpLink = concat(auth, http);
  const wsLink = concat(auth, ws);

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as {
        kind: "OperationDefinition" | "FragmentDefinition";
        operation: String;
      };
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    link: link as unknown as ApolloLink,
    cache: new InMemoryCache(),
  });
};
