import { IIconProps } from "NewComponents/Icons/types";
import React from "react";

export const Thumbs: React.FC<IIconProps> = ({
  size = 24,
  type = "outline",
  color = "white",
  dropShadow = false,
}) => {
  const styles = {
    filter: dropShadow ? "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))" : "none",
  };
  return (
    <svg
      style={styles}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill={type === "fill" ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M7.99997 20H17.1919C17.9865 20 18.7058 19.5296 19.0243 18.8016L21.8323 12.3833C21.9429 12.1305 22 11.8576 22 11.5816V11C22 9.89543 21.1045 9 20 9H13.5L14.7066 4.5757C14.8772 3.95023 14.5826 3.2913 14.0027 3.00136V3.00136C13.4204 2.7102 12.7134 2.87256 12.3164 3.3886L8.41472 8.46082C8.14579 8.81044 7.99997 9.23915 7.99997 9.68024V20ZM7.99997 20H2V10H7.99997V20Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
};
