import React, { useMemo } from "react";
import { clsx } from 'clsx';
import "./styles.css";

export const Loader = ({logo = false, animate = false}) => {

    const className = useMemo(() => {
        let def = "sk-cube-grid"
        if(logo){
            def += " logo"
        }
        return def
    }, [logo])
  return (
    <div className={className}>
      <div className={clsx('sk-cube sk-cube1', animate && 'animate')}></div>
      <div className={clsx('sk-cube sk-cube2', animate && 'animate')}></div>
      <div className={clsx('sk-cube sk-cube3', animate && 'animate')}></div>
      <div className={clsx('sk-cube sk-cube4', animate && 'animate')}></div>
      <div className={clsx('sk-cube sk-cube5', animate && 'animate')}></div>
      <div className={clsx('sk-cube sk-cube6', animate && 'animate')}></div>
      <div className={clsx('sk-cube sk-cube7', animate && 'animate')}></div>
      <div className={clsx('sk-cube sk-cube8', animate && 'animate')}></div>
      <div className={clsx('sk-cube sk-cube9', animate && 'animate')}></div>
    </div>
  );
};
