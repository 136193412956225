import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { appUser } from "Common/AppContext";
import {
  ADD_TAG,
  GET_ALL_TAGS,
  GET_JOB,
  REMOVE_TAG,
  CREATE_TAG,
} from "GraphQL/operations";
import { useCallback } from "react";

export const useTags = (job_id) => {
  const { data = { tags: [] } } = useQuery(GET_ALL_TAGS);
  const [createTag] = useMutation(CREATE_TAG, {
    refetchQueries: [{ query: GET_ALL_TAGS }],
  });
  const [_addTag] = useMutation(ADD_TAG);
  const [_removeTag] = useMutation(REMOVE_TAG);
  const user = useReactiveVar(appUser);

  const addTag = useCallback(
    async (tag: string) => {
      const tagObject = data.tags.find(({ name }) => name === tag);
      let tag_id = "";
      const user_id = user.id;
      if (tagObject) {
        tag_id = tagObject.id;
      } else {
        const { data: createTagData } = await createTag({
          variables: { name: tag },
        });
        tag_id = createTagData.insert_tags.returning[0].id;
      }
      const variables = {
        tag_id,
        job_id,
        user_id,
      };
      await _addTag({
        variables,
        refetchQueries: [{ query: GET_JOB, variables: { id: job_id } }],
      });
    },
    [job_id, data, createTag, _addTag, user]
  );

  const removeTag = useCallback(
    async (tag: string) => {
      const tagObject = data.tags.find(({ name }) => name === tag);
      let tag_id = tagObject.id;
      await _removeTag({ variables: { tag_id, job_id } });
    },
    [job_id, data, _removeTag]
  );

  return { addTag, removeTag, tags: data.tags };
};
