import React from "react";
import { Loader } from "NewComponents/Loader/Loader";
import "./styles.css";
import clsx from "clsx";
export const PageLoader = ({ transparent = true }) => {
  return (
    <div className={clsx("page-loader", transparent && "transparent")}>
      {transparent ? (
        <div className="container">
          <Loader animate />
        </div>
      ) : (
        <Loader animate />
      )}
    </div>
  );
};
