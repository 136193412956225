import { useQuery } from "@apollo/client";
import React, { useCallback } from "react";
import { GET_PROFILES } from "GraphQL/operations";
import { Avatar } from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import { Search } from "Features/Profiles/Search";
import { Page } from "NewComponents/Page/Page";
import { Content } from "NewComponents/Content/Content";
import { Text } from "NewComponents/Text/Text";
import { PageLoader } from "NewComponents/PageLoader/PageLoader";

export type BadgeColor =
  | "neutral"
  | "automatic"
  | "blue"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "purple";

export const Profiles = () => {
  const {
    data = { profiles: [] },
    loading,
    refetch,
  } = useQuery(GET_PROFILES, {
    variables: { search: "%%" },
  });

  const navigate = useNavigate();

  const handleSearch = useCallback(
    (searchString) => {
      refetch({
        search: `%${searchString}%`,
      });
    },
    [refetch]
  );

  const renderProfiles = useCallback(() => {
    const profiles = data.profiles;

    if (profiles.length) {
      return (
        <Content scroll spaceBetween flex wrap height="90%">
          {profiles.map(({ id, name, username }) => (
            <div
              key={id}
              style={{
                width: "250px",
                display: "flex",
                alignItems: "center",
                padding: "8px",
                paddingTop: "16px",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/profiles/${username}`)}
            >
              <Avatar name={name || username} size={48} />
              <span
                style={{
                  marginLeft: "16px",
                  fontWeight: "bold",
                  color: "var(--app-theme)",
                }}
              >
                {name || username}
              </span>
            </div>
          ))}
        </Content>
      );
    } else {
      return (
        <Content middle height="90%">
          <Text bold textCenter>
            It's kinda lonely in here
          </Text>
        </Content>
      );
    }
  }, [data.profiles, navigate]);

  return (
    <Page opaque height="85%">
      <Search updateFilter={handleSearch} />
      {!loading ? (
        renderProfiles()
      ) : (
        <Content height="90%">
          <PageLoader transparent={false} />
        </Content>
      )}
    </Page>
  );
};
