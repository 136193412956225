import React from "react";
import "./styles.css";

export type TTagsProps = {
  onClick?: () => void;
  tag: string;
};

export const Tags: React.FC<TTagsProps> = ({ onClick = () => {}, tag }) => {
  return (
    <div className="app-tag" onClick={onClick}>
      <div className="tag">
        <span>{tag}</span>
      </div>
    </div>
  );
};
