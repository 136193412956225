import { useSubscription } from "@apollo/client";
import { Entity } from "Components/Entity/Entity";
import { GET_JOBS_WITH_TAG } from "GraphQL/operations";
import { Content } from "NewComponents/Content/Content";
import { Page } from "NewComponents/Page/Page";
import { PageLoader } from "NewComponents/PageLoader/PageLoader";
import { Text } from "NewComponents/Text/Text";
import React from "react";
import { useParams } from "react-router-dom";

export const TagsMain = () => {
  const { tag } = useParams();
  const { data = { jobs: [] }, loading } = useSubscription(GET_JOBS_WITH_TAG, {
    variables: { tag },
    skip: !tag,
  });

  const renderEntities = () => {
    const jobs = data.jobs;

    if (jobs?.length) {
      return (
        <Content middle flex row wrap scroll height="auto" maxHeight="100%">
          {jobs.map((job) => (
            <Entity key={job.id} job={job} />
          ))}
        </Content>
      );
    } else {
      return (
        <Content middle>
          <Text bold textCenter>
            It's kinda lonely in here
          </Text>
        </Content>
      );
    }
  };

  return (
    <Page height="85%" opaque={false}>
      {!loading ? (
        renderEntities()
      ) : (
        <Content>
          <PageLoader transparent />
        </Content>
      )}
    </Page>
  );
};
