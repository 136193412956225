import React, { ReactNode } from "react";
import "./styles.css";
import clsx from "clsx";

export type TContentProps = {
  children?: ReactNode;
  middle?: boolean;
  wrap?: boolean;
  spaceBetween?: boolean;
  row?: boolean;
  flex?: boolean;
  height?: string;
  scroll?: boolean;
  maxHeight?: string;
};

export const Content: React.FC<TContentProps> = ({
  children,
  middle = false,
  wrap = false,
  row = false,
  spaceBetween = false,
  flex = false,
  height = "100%",
  scroll = false,
  maxHeight = null,
}) => {
  const classes = clsx(
    "content",
    flex && "content-flex",
    middle && "content-middle",
    wrap && "content-flex-wrap",
    row && "content-flex-row ",
    spaceBetween && "content-flex-justify-content-sb ",
    scroll && "content-scroll"
  );

  const styles = {
    height,
    maxHeight,
  };

  return (
    <div style={styles} className={classes}>
      {children}
    </div>
  );
};
