import React from "react";
import "./styles.css";

export type IImageProps = {
  onClick?: () => void;
  imageSrc: string | undefined;
  bottomLeftElement?: React.ReactNode;
  bottomRightElement?: React.ReactNode;
  topLeftElement?: React.ReactNode;
  topRightElement?: React.ReactNode;
};

export const Image: React.FC<IImageProps> = ({
  imageSrc,
  onClick,
  topLeftElement,
  topRightElement,
  bottomLeftElement,
  bottomRightElement,
}) => {
  return (
    <div className="app-image-container" onClick={onClick}>
      <img src={imageSrc} alt="" className="app-image-element" />
      <div className="image-content-top">
        <div className="left-details">{topLeftElement}</div>

        <div className="right-details">{topRightElement}</div>
      </div>
      <div className="image-content-bottom">
        <div className="left-bottom-details">{bottomLeftElement}</div>

        <div className="right-bottom-details">{bottomRightElement}</div>
      </div>
    </div>
  );
};
