import { IconButton } from "evergreen-ui";
import React from "react";
import "./styles.css";
import { Trash } from "NewComponents/Icons/Trash/Trash";

export const DeleteButton = (props) => {
  return (
    <div className="icon-button">
      <IconButton
        icon={<Trash color="red" type="fill" size={20} dropShadow />}
        {...props}
      />
    </div>
  );
};
