import { useQuery, useSubscription } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_FAVORITES, GET_JOB_FAVORITE_COUNT } from "GraphQL/operations";
import { Pagination } from "evergreen-ui";
import { Entity } from "Components/Entity/Entity";
import { useSearchParams } from "react-router-dom";
import { Page } from "NewComponents/Page/Page";
import { Content } from "NewComponents/Content/Content";
import { PageLoader } from "NewComponents/PageLoader/PageLoader";
import { Text } from "NewComponents/Text/Text";

export type BadgeColor =
  | "neutral"
  | "automatic"
  | "blue"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "purple";

const PER_PAGE = 40;

export const Favorites = () => {
  const [params, setParams] = useSearchParams();
  const [page, setPage] = useState(Number(params.get("p")) || 1);
  const [pages, setPages] = useState(1);
  const { data = { jobs: [] }, loading } = useSubscription(GET_FAVORITES, {
    variables: {
      offset: (page - 1) * PER_PAGE,
    },
    shouldResubscribe: true,
    fetchPolicy: "cache-first",
  });

  const { data: jobsCount } = useQuery(GET_JOB_FAVORITE_COUNT);
  useEffect(() => {
    const { count } = jobsCount?.jobs_aggregate?.aggregate || {};
    const totalPages = count ? Math.ceil(count / PER_PAGE) : 1;
    setPages(totalPages);
  }, [setPages, jobsCount]);

  const renderPagination = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          className="pagination"
          page={page}
          totalPages={pages}
          onPageChange={(no) => {
            setPage(no);
            setParams({ p: String(no) });
            window.scrollTo(0, 0);
          }}
          onNextPage={() => {
            const p = page === pages ? page : page + 1;
            setPage(p);
            setParams({ p: String(p) });
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          onPreviousPage={() => {
            const p = page === 1 ? page : page - 1;
            setPage(p);
            setParams({ p: String(p) });
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        />
      </div>
    );
  };

  const renderEntities = () => {
    const jobs = data.jobs;

    if (jobs?.length) {
      return (
        <>
          {renderPagination()}
          <Content middle flex row wrap scroll height="85%">
            {jobs.map((job) => (
              <Entity key={job.id} job={job} />
            ))}
          </Content>
          {renderPagination()}
        </>
      );
    } else {
      return (
        <Content middle>
          <Text bold textCenter>
            It's kinda lonely in here
          </Text>
        </Content>
      );
    }
  };

  return (
    <Page opaque={false} height="85%">
      {!loading ? (
        renderEntities()
      ) : (
        <Content>
          <PageLoader transparent={false} />
        </Content>
      )}
    </Page>
  );
};
